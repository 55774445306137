import Alueet from './Alueet';
import Asuntokunta from './Asuntokunta';
import AsuntokuntaHarrastaneet from './AsuntokuntaHarrastaneet';
import AsuntokuntaKalastaneet from './AsuntokuntaKalastaneet';
import AsuntokuntaKalastuspaivat from './AsuntokuntaKalastuspaivat';
import AsuntokuntaOsallistuneet from './AsuntokuntaOsallistuneet';
import AsuntokuntaPyydykset from './AsuntokuntaPyydykset';
import Kalastusmatkat from './Kalastusmatkat';
import Kiitos from './Kiitos';
import Lisatiedot from './Lisatiedot';
import Login from './Login';
import Ohjeet from './Ohjeet';
import PyydyksetAlueittain from './PyydyksetAlueittain';
import SaaliitMaarat from './SaaliitMaarat';
import SaaliitPyydyksittain from './SaaliitPyydyksittain';
import SaaliitRavinnoksi from './SaaliitRavinnoksi';
import SaaliitVapautetut from './SaaliitVapautetut';
import Vastauskoodi from './Vastauskoodi';
import Yhteenveto from './Yhteenveto';

export {
  Alueet,
  Asuntokunta,
  AsuntokuntaKalastaneet,
  AsuntokuntaKalastuspaivat,
  AsuntokuntaHarrastaneet,
  AsuntokuntaOsallistuneet,
  AsuntokuntaPyydykset,
  Kalastusmatkat,
  Kiitos,
  Lisatiedot,
  Login,
  Ohjeet,
  PyydyksetAlueittain,
  SaaliitMaarat,
  SaaliitPyydyksittain,
  SaaliitRavinnoksi,
  SaaliitVapautetut,
  Yhteenveto,
  Vastauskoodi,
};
