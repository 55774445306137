{
  "welcome": {
    "content": "Tervetuloa vastaamaan Luonnonvarakeskuksen vapaa-ajan kalastuksen kyselyyn. Aloita vastaaminen syöttämällä vastaajatunnuksesi. Vastaajatunnuksen löydät kyselylomakkeen alusta."
  },
  "lang": {
    "fi": "Suomeksi",
    "en": "In English",
    "sv": "På svenska"
  },
  "common": {
    "start": "Aloita",
    "next": "Seuraava",
    "previous": "Edellinen",
    "close": "Sulje",
    "send": "Lähetä tiedot",
    "logout": "Kirjaudu ulos",
    "women": "Naisia",
    "men": "Miehiä",
    "count": "Lukumäärä",
    "amount": "Määrä",
    "catch": "Pyydys",
    "species": "Laji",
    "caught": "Saalis",
    "caught_up": "Ylösotettu saalis",
    "released_catch": "Vapautettu saalis",
    "released": "Vapautettu",
    "loginkey": "Vastaajatunnus",
    "login_failed": "Kirjautuminen epäonnistui. Tarkista vastaajatunnus",
    "open_user_menu": "Avaa käyttäjätoiminnot",
    "area_type_sea": "merialue",
    "errors": {
      "invalid_value": "Tarkista syöttämäsi arvo."
    },
    "map": "Suomen kartta",
    "units": {
      "kg": "kg",
      "kpl": "kpl"
    },
    "example": "Esimerkki",
    "title": "Vapaa-ajankalastus 2024",
    "lukeLogoAlt": "Luonnonvarakeskuksen logo",
    "officialStatisticsLogoAlt": "Suomen Virallinen Tilasto",
    "datetime": "{{date, datetime}}"
  },

  "vastauskoodit": {
    "title": "Vastauskoodit",

    "contactedByControl": {
      "error": "Valitse yksi vaihtoehdoista.",
      "label": "Yhteydenottotapa",
      "option1": "Sähköposti ",
      "option2": "Puhelin",
      "option3": "Kirjeposti"
    },
    "vastauskoodiControl": {
      "error": "Valitse yksi vaihtoehdoista.",
      "label": "Vastauskoodi",
      "option1": "Vastaus hylätty",
      "option2": "Posti palauttanut",
      "option3": "Kieltäytyy tietojen antamisesta",
      "option4": "Tiedot voidaan antaa"
    },
    "vastauskoodi_lisatieto": "Lisätiedot"
  },

  "asuntokunta": {
    "title": "Kalastiko tai ravustiko kukaan asuntokuntanne jäsenistä Suomessa vuonna 2024?",
    "instructions1": "Asuntokunnan muodostavat kaikki samassa asuinhuoneistossa vakinaisesti asuvat henkilöt.",
    "instructions2": "Kalastamiseksi katsotaan se, että on käyttänyt mitä tahansa pyyntimuotoa (esim. verkot, katiskat, onget, pilkkivavat yms.) ainakin kerran vuoden 2024 aikana. Henkilön katsotaan kalastaneen, vaikka hän olisi vain soutanut tai ohjannut venettä toisen kalastaessa.",
    "control": {
      "label": "Asuntokunnan kalastus",
      "option1": "Kyllä, ja sai saalista.",
      "option2": "Kyllä, mutta kukaan ei saanut saalista.",
      "option3": "Kukaan asuntokunnan jäsenistä ei kalastanut vuonna 2024.",
      "error": "Valitse yksi vaihtoehdoista."
    }
  },
  "asuntokunta_kalastaneet": {
    "title": "Merkitkää seuraavaan taulukkoon asuntokuntanne Suomessa vuonna 2024 kalastaneiden (tai ravustaneiden) henkilöiden lukumäärät.",
    "instructions1": "Kalastamiseksi katsotaan se, että on käyttänyt mitä tahansa pyyntimuotoa (esim. verkot, katiskat, onget, pilkkivavat yms.) ainakin kerran vuoden 2024 aikana. Henkilön katsotaan kalastaneen, vaikka hän olisi vain soutanut tai ohjannut venettä toisen kalastaessa.",
    "control": {
      "error1": "Syötä enintään 20 miestä tai naista per ikäryhmä."
    }
  },
  "asuntokunta_osallistuneet": {
    "title": "Kuinka moni asuntokuntanne jäsenistä ei kalastanut lainkaan tai osallistui kalastamiseen vain soutamalla tai ohjaamalla venettä vuonna 2024?",
    "control": {
      "option1": "Ei kalastanut lainkaan.",
      "option2": "Osallistui kalastamiseen ainoastaan soutamalla tai ohjaamalla venettä."
    }
  },
  "asuntokunta_harrastaneet": {
    "title": "Kuinka moni asuntokuntanne kalastaneista jäsenistä kuului johonkin seuraavista ryhmistä kalastuksensa tai ravustuksensa perusteella vuonna 2024?",
    "instructions1": "Merkitkää jokainen kalastaneista henkilöistä kuuluvaksi vain yhteen ryhmään.",
    "control": {
      "option1": "Kalastus ei ollut lainkaan tärkeä harrastus.",
      "option2": "Kalastus ei ollut kovin tärkeä harrastus",
      "option3": "Kalastus oli melko tärkeä harrastus",
      "option4": "Kalastus oli erittäin tärkeä harrastus."
    }
  },
  "asuntokunta_kalastuspaivat": {
    "title": "Mikä oli asuntokuntaanne kuuluvien henkilöiden yhteenlaskettu kalastuspäivien lukumäärä Suomessa vuonna 2024?",
    "instructions1": "Kalastamiseksi katsotaan se, että on käyttänyt mitä tahansa pyyntimuotoa (esim. verkot, katiskat, onget, pilkkivavat yms.) ainakin kerran vuoden 2024 aikana. Henkilön katsotaan kalastaneen, vaikka hän olisi vain soutanut tai ohjannut venettä toisen kalastaessa.",
    "instructions2": "Esimerkki: Henkilö 1 kalasti mato-ongella 10 päivänä. Lisäksi hän osallistui kolmena päivänä verkonnostoon. Henkilö 2 kalasti verkoilla kolmena päivänä. Asuntokunnan kalastuspäivien lukumäärä 2024 on 16.",
    "control": "Kalastuspäiviä yhteensä"
  },
  "pyydykset_ikaryhmittain": {
    "title": "Merkitkää eri pyydyksiä Suomessa vuonna 2024 käyttäneiden asuntokuntanne henkilöiden lukumäärät ikäryhmittäin."
  },
  "pyydykset_alueittain": {
    "title": "Merkitkää eri pyydyksiä Suomessa vuonna 2024 käyttäneiden asuntokuntanne henkilöiden lukumäärät ikäryhmittäin, sekä henkilöiden kalastuspäivät yhteensä.",
    "instructions1": "Kalastuspäivällä tarkoitetaan vapapyydysten osalta sitä, että yksi henkilö on kalastanut tietyllä vapapyydyksellä tai vetouistimilla yhtenä päivänä. Verkko-, katiska-, merta- ja rysäpyydysten osalta kalastuspäivällä tarkoitetaan sitä, että henkilö on kokenut kyseisen tyyppisiä pyydyksiä yhtenä päivänä.",
    "instructions2": "Esimerkki: Kaksi henkilöä on kokenut yhdessä verkkoja neljänä päivänä Kainuussa. Henkilömääräksi merkitään 2 ja kalastuspäivien lukumääräksi 8.",
    "control": {
      "label1": "Henkilöitä yhteensä",
      "label2": "Kalastuspäiviä yhteensä",
      "label3": "Muu pyydys - mikä?"
    },
    "error": {
      "henkilot_required": "Syötä myös henkilöiden määrä.",
      "kalastuspaivat_required": "Syötä myös kalastuspäivien määrä."
    }
  },
  "alueet": {
    "title": "Valitkaa alueet, joilla asuntokuntanne kalasti vuonna 2024.",
    "instructions1": "Voit valita alueet pudotusvalikosta tai klikkaamalla karttaa.",
    "control": {
      "label": "Valitse alueet"
    }
  },
  "kalastusmatkat": {
    "title": "Merkitkää asuntokuntanne kotimaan kalastusmatkojen määrä sekä kalastusmatkojen kustannukset.",
    "instructions1": "Kalastusmatkalla tarkoitetaan päiväkäyntiä tai useamman päivän pituista matkaa, jonka <strong>pääasiallinen tarkoitus on kalastus</strong>. Ilmoittakaa asuntokunnan kaikkien henkilöiden kalastusmatkojen ja kustannuksien yhteismäärä. Älkää ilmoittako <strong>kalastonhoitomaksua</strong> kalastuslupakuluissa.",
    "instructions2": "<i><strong>Esimerkki</strong>: Asuntokunnan kaksi henkilöä teki yhdessä kaksi kalastusmatkaa Uudenmaan merialueelle. Kalastusmatkojen määräksi kohdassa a merkitään 4. Kustannuksien määräksi ilmoitetaan molempien kulut yhteensä.</i>",
    "example1": "Asuntokunnan kaksi henkilöä teki 2 erillistä yhden päivän kalastusmatkaa. Kalastusmatkojen määräksi kohdassa a merkitään 4.",
    "example2": "Asuntokunnan kaksi henkilöä teki yhdessä 3 ja 4 päivää kestäneet kalastusmatkat. Kalastusmatkojen määräksi kohdassa b merkitään 4 ja kalastuspäivien määräksi kohdassa c merkitään 14.",
    "example3": "Yksi henkilö teki kaksi kalastusmatkaa Norjaan sekä kalasti kesällä vieraillessaan sukulaistensa mökillä. Kalastusmatkojen määräksi merkitään 0.",
    "link": "Katso tarvittaessa esimerkit ohjesivulta.",
    "control": {
      "label1": "Päiväkäyntien määrä",
      "label2": "Kalastusmatkojen määrä",
      "label3": "Kalastuspäivien määrä kalastusmatkoilla",
      "label4": "Polttoainekulut",
      "label5": "Matkalippukulut (bussi, juna, lennot)",
      "label6": "Majoituskulut",
      "label7": "Kahvila- ja/tai ravintolakulut",
      "label8": "Ruoka- ja/tai päivittäistavarat",
      "label9": "Kalastuslupakulut",
      "label10": "Kalastuspalvelut (opas ym.)",
      "label11": "Muut ostot ja menot"
    }
  },
  "kalastusvalineet": {
    "veneily": "Kalastukseen ensisijaisesti liittyvät vene- ja venetarvikehankinnat vuonna 2024",
    "euroa": "Kalastusvälinehankinnat vuonna 2024 (esim. vapoja, verkkoja, kalastuselektroniikkaa)",
    "label": "Hankittiinko asuntokunnassanne kalastusvälineitä vuonna 2024?",
    "name": "kalastusvalineet",
    "hankittu": {
      "hankittu": "Kyllä",
      "ei_hankittu": "Ei"
    }
  },
  "saaliit_pyydyksittain": {
    "title": "Merkitkää lajit, joita asuntokuntanne sai saaliiksi (sisältää sekä saaliiksi otetun että vapautetun saaliin) kalastusalueittain ja pyydyksittäin vuonna 2024.",
    "control": {
      "label1": "Valitse lajit",
      "label2": "Muu laji - mikä?"
    }
  },
  "saaliit_maarat": {
    "title": "Arvioikaa alla olevaan taulukkoon asuntokuntanne ylösotettu saalis Suomesta vuonna 2024 \n (EI SISÄLLÄ ELÄVÄNÄ VAPAUTETTUA SAALISTA).",
    "instructions1": "Arvioikaa saaliit perkaamattomana painona."
  },
  "saaliit_ravinnoksi": {
    "title": "Arvioikaa lajeittain ihmisravinnoksi käytetyn saaliin osuus kiloina.",
    "control": {
      "label1": "Ravinnoksi"
    },
    "error": {
      "max_percentage": "Syötä arvoksi enintään 100%"
    }
  },
  "saaliit_vapautetut": {
    "title": "Arvioikaa asuntokuntanne elävänä vapauttama saalis.",
    "error": {
      "released_exceeds_caught": "Vapautetun saaliin määrä ei voi olla suurempi kuin ilmoitettu saalis."
    }
  },
  "lisatiedot": {
    "title": "Muuta tietoa asuntokuntanne vapaa-ajankalastuksesta",
    "control": {
      "label1": "Lisätiedot"
    }
  },
  "yhteenveto": {
    "title": "Yhteenveto",
    "instructions1": "Saat ladattua vastaukset itsellesi painikkeesta <strong>Lataa PDF</strong>.",
    "instructions2": "Kun olet tarkistanut vastauksesi, paina <strong>Lähetä</strong>. Voit palata muokkaamaan vastauksia tietojen lähettämisen jälkeen. Jos muutat vastauksia, lähetä tiedot uudelleen.",
    "kalastusmatkat_warning": "Syötit yli 100 kalastusmatkaa per alue.",
    "kalastusmatkat_kulut_warning": "Syötit kuluiksi yli 6000 euroa. Veneiden ja kalastusvälineiden hankintakuluja, vakuutuskuluja ja muita vastaavia kuluja ei tule ilmoittaa. Tarkista antamasi tiedot ja tarvittaessa muuta vastauksiasi",
    "saaliit_maarat_warning": "Syötit saaliiksi yli 100 kiloa per laji.",
    "check_answers": "Tarkista antamasi tiedot ja tarvittaessa muuta vastauksiasi.",
    "saveAsPDF": "Lataa PDF"
  },
  "kiitos": {
    "title": "Kiitos vastauksistasi!",
    "link": "tästä.",
    "stat_info": "Lisätietoja vapaa-ajankalastuksesta löydät Luonnonvarakeskuksen vapaa-ajankalastustilaston sivuilta:",
    "stat_link": "https://www.luke.fi/fi/tilastot/vapaaajankalastus"
  },
  "ohjeet": {
    "title": "Ohjeet",
    "title1": "Kirjautuminen",
    "title2": "Vastaaminen",
    "title3": "Kysymykset ja palaute",
    "instructions1": "Sovellukseen kirjaudutaan 12-merkkisellä vastaajatunnuksella, joka löytyy postitse saapuneen tiedonkeruulomakkeen oikeasta yläkulmasta. Vastaajatunnus on henkilökohtainen. Mikäli käytössäsi olevassa mobiililaitteessa on QR-koodinlukija, voit lukea kirjautumislinkin tiedonkeruulomakkeella olevasta QR-koodista.",
    "instructions2": "Halutessasi voit keskeyttää vastaamisen, kirjautua ulos ja jatkaa myöhemmin kirjautumalla uudelleen sisään.  Uloskirjautuminen tapahtuu oikeasta yläkulmasta löytyvästä valikosta.",
    "instructions3": "Voit liikkua kysymysten välillä ruudun alareunasta löytyvillä ”edellinen” ja ”seuraava”-painikkeilla.",
    "instructions4": "muodostavat kaikki samassa asuinhuoneistossa vakinaisesti asuvat henkilöt.",
    "instructions4_bold": "Asuntokunnan",
    "instructions5_bold": "Kalastamiseksi",
    "instructions5": "katsotaan se, että on käyttänyt mitä tahansa pyyntimuotoa (esim. verkot, katiskat, onget, pilkkivavat, rapumerrat yms.) ainakin kerran vuoden 2024 aikana. Henkilön katsotaan kalastaneen, vaikka hän olisi vain soutanut tai ohjannut venettä toisen kalastaessa.",
    "instructions6": "Kysely koskee ainoastaan Suomessa tapahtunutta kalastusta ja ravustusta. Ulkomailla tapahtunutta kalastusta tai ulkomailla saatua saalista ei tule ilmoittaa.",
    "kalastusmatkat": "Asuntokunnan kalastusmatkat - esimerkkivastauksia"
  }
}
