{
  "alueet": {
    "control": {
      "label": "Välj områden"
    },
    "instructions1": "Du kan antingen välja områdena i rullgardinsmenyn eller klicka på kartan.",
    "title": "Ange de områden där hushållet fiskade under 2024."
  },
  "asuntokunta_harrastaneet": {
    "control": {
      "option1": "Fiske var inte alls ett viktigt fritidsintresse.",
      "option2": "Fiske var ett lite viktigt fritidsintresse.",
      "option3": "Fiske var ett ganska viktigt fritidsintresse.",
      "option4": "Fiske var ett mycket viktigt fritidsintresse."
    },
    "instructions1": "Placera varje medlem i någon av grupperna nedan. Varje medlem kan endast höra till en grupp.",
    "title": "Hur många medlemmar som idkade fiske i ert bostadshushåll hörde på basis av sitt fiske under år 2024 till följande grupper?"
  },
  "asuntokunta_kalastaneet": {
    "control": {
      "error1": "Det maximala värdet är 20."
    },
    "instructions1": "Med fiske anses att man oberoende av fångstsätt (t.ex. nät, katsa, metspö, pilkspö m.m.) fiskat minst en gång under år 2024. En person anses ha idkat fiske även om hon/han endast har rott eller styrt båten från vilken någon annan fiskat.",
    "title": "Anteckna i nedanstående tabell hur många ur ert bostadshushåll fiskat eller fångat kräftor i Finland under år 2024."
  },
  "asuntokunta_kalastuspaivat": {
    "control": "Fiskedagar tillsammans",
    "instructions1": "Med fiske anses att man oberoende av fångstsätt (t.ex. nät, katsa, metspö, pilkspö m.m.) fiskat minst en gång under år 2024. En person anses ha idkat fiske även om hon/han endast har rott eller styrt båten från vilken någon annan fiskat.",
    "instructions2": "Personen 1 fiskade med ett metspö i 10 dagar. Dessutom fiskade han med nät i tre dagar. Personen 2 fiskade med nät i tre dagar. Antalet fiskedagar i bostaden för 2024 är 16.",
    "title": "Hur många fiskedagar hade medlemmarna i ert bostadshushåll tillsammans i Finland år 2024?"
  },
  "asuntokunta_osallistuneet": {
    "control": {
      "option1": "Idkade inte alls fiske.",
      "option2": "Deltog i fisket endast genom att ro eller styra båten."
    },
    "title": "Hur många medlemmar i ert bostadshushåll idkade inte alls fiske eller deltog i fisket endast genom att ro eller styra båten?"
  },
  "asuntokunta": {
    "control": {
      "error": "Välj ett av alternativen.",
      "label": "Bostadshushållets fritidsfiske",
      "option1": "Ja, och fick fångst.",
      "option2": "Ja, men ingen fick fångst.",
      "option3": "Nej, ingen idkade fiske eller fångade kräftor år 2024."
    },
    "instructions1": "Ett bostadshushåll bildas av alla personer som stadigvarande bor i samma bostadslägenhet.",
    "instructions2": "Med fiske anses att man oberoende av fångstsätt (t.ex. nät, katsa, metspö, pilkspö m.m.) fiskat minst en gång under år 2024. En person anses ha idkat fiske även om hon/han endast har rott eller styrt båten från vilken någon annan fiskat.",
    "title": "Har någon medlem ur ert bostadshushåll idkat fiske eller fångat kräftor i Finland under år 2024? "
  },
  "common": {
    "amount": "Antal",
    "area_type_sea": "havområdet",
    "catch": "Fiskeredskap",
    "caught": "Fångst",
    "caught_up": "Avlivet fångst",
    "released_catch": "Återutsatt fångst",
    "close": "Föregående",
    "count": "Antal",
    "datetime": "{{date, datetime}}",
    "errors": {
      "invalid_value": "Kontrollera det värde du har angett."
    },
    "example": "Exampel",
    "login_failed": "Inkorrekt svararkod.",
    "loginkey": "Svararkod",
    "logout": "Logga ut",
    "lukeLogoAlt": "Naturresursinstitutets logo",
    "map": "Karta över Finland",
    "men": "Män",
    "next": "Nästa",
    "officialStatisticsAlt": "Finlands officiella statistik",
    "officialStatisticsLogoAlt": "Finlands officiella statistik",
    "open_user_menu": "Öppna app meny",
    "previous": "Föregående",
    "released": "Befriat",
    "send": "Skicka",
    "species": "Art",
    "start": "Börja",
    "title": "Fritidsfiske år 2024",
    "units": {
      "kg": "kg",
      "kpl": "st"
    },
    "women": "Kvinnor"
  },
  "kalastusmatkat": {
    "control": {
      "label1": "Antal dagliga besök",
      "label2": "Antal fiskeresor",
      "label3": "Antal fiskedagar på fiskeresor",
      "label4": "Bränslekostnader",
      "label5": "Resebiljettkostnader (buss, tåg, flyg)",
      "label6": "Boendekostnader",
      "label7": "Kafé- och/eller restaurangkostnader",
      "label8": "Mat och/eller matvaror",
      "label9": "Fisketillståndkostnader",
      "label10": "Fisketjänster (guide, osv.)",
      "label11": "Övriga inköp och utgifter"
    },
    "example1": "Två personer i bostadshushållet har gjort 2 separata fiskeresor som varat 1 dag. Som antal fiskeresor i punkt a anges 4.",
    "example2": "Två personer i bostadshushållet har gjort tillsammans fiskeresor som varat 3 och 4 dagar. Som antal fiskeresor i punkt b anges 4 och som antal fiskedagar i punkt c anges 14.",
    "example3": "En person har gjort 2 fiskeresor till Norge och har på sommaren fiskat under ett besök på släktingars stuga. Som antal fiskeresor anges 0.",
    "instructions1": "Med en fiskeresa avses ett dagsbesök eller en resa som varar flera dagar, vars <strong>huvudsakliga syfte är fiske</strong>. Ange det totala antalet fiskeresor och kostnader för alla hushållsmedlemmar. Ange inte <strong>fiskevårdsavgiften</strong> bland fisketillståndskostnaderna.",
    "instructions2": "<i><strong>Exempel</strong>: Två personer i bostadshushållet har gjort 2 separata fiskeresor till Nylands havsområde. Som antal fiskesresor i punkt a anges 4. Den totala kostnaden anges som summan av kostnaderna för båda.</i>",
    "link": "Se vid behov exempel på anvisningssidan.",
    "title": "Ange antalet inhemska fiskeresor som gjorts av ditt hushåll och kostnader för resor."
  },
  "kalastusvalineet": {
    "euroa": "Inköp av fiskeredskap 2024 (t.ex. spön, nät, beten)",
    "hankittu": {
      "ei_hankittu": "Nej",
      "hankittu": "Ja"
    },
    "label": "Köpte ditt bostadshushåll fiskeredskap under 2024?",
    "name": "fiskeredskap",
    "veneily": "Inköp av båtar och båtutrustning främst relaterade till fiske 2024"
  },
  "kiitos": {
    "link": "Klicka här för att börja svara.",
    "stat_info": "Mer information om fritidsfiske finns i Naturresursinstitutets statistik över fritidsfiske:",
    "stat_link": "https://www.luke.fi/sv/statistik/fritidsfiske",
    "title": "Tack för svaren! Du har loggat ut från appen."
  },
  "lang": {
    "en": "In English",
    "fi": "Suomeksi",
    "sv": "På svenska"
  },
  "lisatiedot": {
    "control": {
      "label1": "Mer information"
    },
    "title": "Mer information om hushållets fritidsfiske"
  },
  "ohjeet": {
    "instructions1": "Logga in i applikationen med den 12-tecken långa svararkoden som finns angiven i övre högra hörnet på pappersblanketten som du fått per post. Koden är personlig. Om du använder en mobil enhet med QR-läsare kan du avläsa inloggningslänken från QR-koden på svarsblanketten.",
    "instructions2": "Du kan när som helst avbryta förfrågan, logga ut och logga senare in igen för att fortsätta. Du loggar ut via menyn högst upp i högra hörnet.",
    "instructions3": "Du kan röra dig mellan frågorna genom att klicka på Föregående och Nästa nertill på sidan.",
    "instructions4": "Ett hushåll består av de personer som bor stadigvarande i samma bostadslägenhet.",
    "instructions5": "Med fiske avses att man har fiskat med något fångstsätt (t.ex. nät, katsa, metspö, pimpelspö, kräftbur) minst en gång under 2024. Personen anses ha fiskat även om hen endast har rott eller styrt båten medan någon annan har fiskat.",
    "instructions6": "Förfrågan gäller endast fiske och kräftfångst i Finland. Fiske eller fångst utomlands ska inte anges.",
    "kalastusmatkat": "Hushållets fiskeresor – exempelsvar",
    "title": "Anvisning",
    "title1": "Logga in",
    "title2": "Att svara",
    "title3": "Kontaktinformation"
  },
  "pyydykset_alueittain": {
    "control": {
      "label1": "Personer totalt",
      "label2": "Fiskedagar",
      "label3": "Annat redskap - vad?"
    },
    "instructions1": "Vid spöfiske avses med fiskedag att en person har fiskat med ett visst spö eller genom dragrodd under en dag. I fråga om nät, katsor, mjärdar och ryssjor avses med fiskedag att en person har vittjat ett sådant fiskeredskap under en dag.",
    "instructions2": "Exempel: Två personer har tillsammans vittjat nät under fyra dagar i Kajanaland. Som antal personer 2 och som antal fiskedagar 8.",
    "title": "Ange antalet personer i ert bostadshushåll som använt fiskeredskap under 2024 per område samt personernas sammanlagda antal fiskedagar.",
    "error": {
      "henkilot_required": "Ange också personer.",
      "kalastuspaivat_required": "Ange också fiskedagar."
    }
  },
  "pyydykset_ikaryhmittain": {
    "title": "Ange antal medlemmar i respektive ålderskategori som år 2024 använt följande fiskeredskap i Finland."
  },
  "saaliit_maarat": {
    "instructions1": "Uppskatta fångstens vikt som orensad fisk.",
    "title": "Uppskatta i nedanstående tabell den sammanlagda fångsten (kilogram, antal för kräftor) ert bostadshushåll erhållit med olika redskap i Finland år 2024 (INNEHÅLLER INTE LEVANDE BEFRIAD FÅNGST)."
  },
  "saaliit_pyydyksittain": {
    "control": {
      "label1": "Välja arter",
      "label2": "Annan fisk, vad?"
    },
    "title": "Anteckna de fångstarter som ert bostadshushåll erhållit (både fångsten som har upptagits och fångsten som har befriats) med olika redskap och på olika fiskeområden år 2024."
  },
  "saaliit_ravinnoksi": {
    "control": {
      "label1": "Använts som människoföda"
    },
    "title": "Uppskatta för olika arter, hur många kilo användes som människoföda. ",
    "error": {
      "max_percentage": "Maximalt värde är 100%."
    }
  },
  "saaliit_vapautetut": {
    "title": "Uppskatta i nedanstående tabell den sammanlagda fångsten (kilogram, antal för kräftor) som ert bostadshushåll befriat levande.",
    "error": {
      "released_exceeds_caught": "Den släppta fångsten får inte vara större än den totala fångsten."
    }
  },
  "welcome": {
    "content": "Välkommen att svara på Naturresursinstitutets förfrågan om fritidsfiske! Skrivs först in din svararkod och klicka sedan på Starta nertill på sidan. Koden med 12-tecken finns angiven uppe till höger på pappersblanketten."
  },
  "yhteenveto": {
    "check_answers": "Tarkista antamasi tiedot ja tarvittaessa muuta vastauksiasi.",
    "instructions1": "Du kan ladda ner svaren till dig själv med knappen <strong>Ladda ner PDF.</strong>",
    "instructions2": "Efter att du har kontrollerat dina svar, klicka på <strong>Skicka</strong>. Du kan gå tillbaka för att redigera svaren efter att uppgifterna har skickats. Om du ändrar svaren, skicka uppgifterna på nytt.",
    "kalastusmatkat_warning": "Syötit yli 100 kalastusmatkaa per alue.",
    "kalastusmatkat_kulut_warning": "Du har spenderat mer än 6 000 euro. Kostnader för inköp av båtar och fiskeutrustning, försäkringar och liknande kostnader bör inte anges. Vänligen kontrollera den information du har lämnat och ändra dina svar om det behövs.",
    "saaliit_maarat_warning": "Syötit saaliiksi yli 100 kiloa per laji.",
    "saveAsPDF": "Ladda ner PDF",
    "title": "Sammandrag"
  }
}
