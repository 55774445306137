{
  "alueet": {
    "control": {
      "label": "Select areas"
    },
    "instructions1": "You can select areas from the dropdown or by clicking them on the map.",
    "title": "Select the areas where your household went fishing in the year 2024."
  },
  "asuntokunta_harrastaneet": {
    "control": {
      "option1": "Fishing was not an important hobby at all.",
      "option2": "Fishing was not that important a hobby.",
      "option3": "Fishing was quite an important hobby.",
      "option4": "Fishing was an extremely important hobby."
    },
    "instructions1": "Select only one group for each person who fished in 2024.",
    "title": "How many of the members of your household who fished belonged to any of the following groups based on fishing or catching crayfish in 2024?"
  },
  "asuntokunta_kalastaneet": {
    "control": {
      "error1": "The maximum allowed value is 20."
    },
    "instructions1": "Fishing is considered as such when a person has used gear of some kind (e.g., fishing net, fish trap, rod and line, or ice fishing rod) at least once during 2024. A person is considered to have fished even if they had only rowed or steered the boat while someone else was fishing.",
    "title": "Mark the number of people belonging to your household who fished (or caught crayfish) in Finland in 2024."
  },
  "asuntokunta_kalastuspaivat": {
    "control": "Fishing days in total",
    "instructions1": "Fishing is considered as such when a person has used gear of some kind (e.g., fishing net, fish trap, rod and line, or ice fishing rod) at least once in 2024. A person is considered to have fished even if they had only rowed or steered the boat while someone else was fishing.",
    "instructions2": "Example: Person 1 fished using a rod and line for ten days. In addition, they took part in hauling nets for three days. Person 2 fished using fishing nets for three days. The total number of fishing days for the household amounts to 16 in 2024. ",
    "title": "In total, how many days did the members of your household fish in Finland in 2024?"
  },
  "asuntokunta_osallistuneet": {
    "control": {
      "option1": "Did not fish at all.",
      "option2": "Took part in fishing only by rowing or steering the boat."
    },
    "title": "How many of the members of your household did not fish at all or took part in fishing only by rowing or steering the boat in 2024?"
  },
  "asuntokunta": {
    "control": {
      "error": "Please select an option.",
      "label": "Household's recreational fishing",
      "option1": "Yes, and they caught fish.",
      "option2": "Yes, but nobody caught any fish.",
      "option3": "None of the members of the household went fishing in 2024."
    },
    "instructions1": "One household unit consists of all the persons living permanently in the same dwelling.",
    "instructions2": "Fishing is considered as such when a person has used gear of some kind (e.g., fishing net, fish trap, rod and line, or ice fishing rod) at least once in 2024. A person is considered to have fished even if they had only rowed or steered the boat while someone else was fishing.",
    "title": "Did any of the members of your household fish or catch crayfish in Finland in 2024?"
  },
  "common": {
    "amount": "Amount",
    "area_type_sea": "sea area",
    "catch": "Gear",
    "caught": "Catch",
    "caught_up": "Retained catch",
    "released_catch": "Released catch",
    "close": "Close",
    "count": "Count",
    "datetime": "{{date, datetime}}",
    "errors": {
      "invalid_value": "Check the value you have entered."
    },
    "example": "Example",
    "login_failed": "Login failed. Please check your respondent ID.",
    "loginkey": "Respondent ID",
    "logout": "Logout",
    "lukeLogoAlt": "The logo of Natural Resources Institute Finland",
    "map": "Map of Finland",
    "men": "Men",
    "next": "Next",
    "officialStatisticsAlt": "Official Statistics of Finland",
    "officialStatisticsLogoAlt": "Official Statistics of Finland",
    "open_user_menu": "Open app menu",
    "previous": "Previous",
    "released": "Released",
    "send": "Send",
    "species": "Species",
    "start": "Start",
    "title": "Recreational fishing in 2024",
    "units": {
      "kg": "kg",
      "kpl": "units"
    },
    "women": "Women"
  },
  "kalastusmatkat": {
    "control": {
      "label1": "Number of daily visits",
      "label2": "Number of fishing trips",
      "label3": "Number of fishing days on fishing trips",
      "label4": "Fuel costs",
      "label5": "Travel ticket expenses (bus, train, flights)",
      "label6": "Accommodation expenses",
      "label7": "Café and/or restaurant expenses",
      "label8": "Food and/or groceries",
      "label9": "Fishing licence expenses",
      "label10": "Fishing services (guide, etc.)",
      "label11": "Other purchases and expenses"
    },
    "example1": "2 people in the household made two separate fishing trips of one day. The total number of fishing trips in field a should be 4.",
    "example2": "2 people in the household made fishing trips of 3 and 4 days together. The number of fishing trips in field b is 4 and the number of fishing days in field c is 14.",
    "example3": "One person made two fishing trips to Norway and was fishing while visiting a family member's cabin on the summer. The number of fishing trips in this case is zero.",
    "instructions1": "A fishing trip refers to a day visit or a multi-day trip, the <strong>primary purpose of which</strong> is fishing. Report the total number of fishing trips and costs for all household members. Do not include the <strong>fisheries management fee</strong> in the fishing license costs.",
    "instructions2": "<i><strong>Example</strong>: Two persons made two separate fishing trips in Uusimaa sea area. Number of fishing trips in item a is 4, and travel costs are total costs of both persons.</i>",
    "link": "Please refer to the instructions page for examples.",
    "title": "Mark the number of fishing trips that the members of your household made in Finland in 2024, and associated travel costs."
  },
  "kalastusvalineet": {
    "euroa": "Fishing equipment purchases in 2024 (e.g., rods, nets, fishing electronics)",
    "hankittu": {
      "ei_hankittu": "No",
      "hankittu": "Yes"
    },
    "label": "Did your household purchase fishing equipment in 2024?",
    "name": "fishing equipment",
    "veneily": "Boat and boating equipment purchases primarily related to fishing in 2024"
  },
  "kiitos": {
    "stat_info": "For more information on recreational fishing, see the Natural Resources Institute Finland's recreational fishing statistics:",
    "stat_link": "https://www.luke.fi/en/statistics/recreational-fishing",
    "title": "Thank you for participating!"
  },
  "lang": {
    "en": "In English",
    "fi": "Suomeksi",
    "sv": "På svenska"
  },
  "lisatiedot": {
    "control": {
      "label1": "Additional information"
    },
    "title": "Additional information about your household's recreative fishing"
  },
  "ohjeet": {
    "instructions1": "Login to the application using your 12-digit respondent id.Respondent id is the twelve-digit code in the top right corner of the printed form, which has been sent by mail. If your mobile device is capable of reading QR codes, you can read the login link from the QR code in the printed form.",
    "instructions2": "Your answers are saved on each submit. You can logout at any time and return later to finish your answers. You can logout from the user menu on the top right corner of the screen.",
    "instructions3": "Move between answers using the navigation buttons on the bottom of the screen.",
    "instructions4": "unit consists of all the persons living permanently in the same dwelling.",
    "instructions4_bold": "A household ",
    "instructions5": "is considered as such when a person has used gear of some kind (e.g., fishing net, fish trap, rod and line, or ice fishing rod) at least once in 2024.  A person is considered to have fished even if they had only rowed or steered the boat while someone else was fishing.",
    "instructions5_bold": "Fishing",
    "instructions6": "The survey only includes fishing or crayfishing that took place in Finland. Fishing or catches that took place outside Finland should not be included in your answers.",
    "kalastusmatkat": "Household's fishing trips - example answers",
    "title": "Instructions",
    "title1": "Logging in to the application",
    "title2": "Answering",
    "title3": "Questions & feedback"
  },
  "pyydykset_alueittain": {
    "control": {
      "label1": "Total number of people",
      "label2": "Number of fishing days",
      "label3": "Other gear - please specify!"
    },
    "instructions1": "In the case of angling, ‘fishing day’ means that one person has used a certain type of rod or trolling gear during one day. In the case of gill nets, fish traps, crayfish traps, and trap nets, ‘fishing day’ means that a person has hauled the gear in question during one day.",
    "instructions2": "Example: Two persons hauled their nets in four days in Kainuu. The number of people is 2, and the number of fishing days is 8.",
    "title": "Mark the number of people who belong to your household and used different types of gear in 2024 by region, as well as the total number of fishing days.",
    "error": {
      "henkilot_required": "Enter the total number of people.",
      "kalastuspaivat_required": "Enter the total number of fishing days."
    }
  },
  "pyydykset_ikaryhmittain": {
    "title": "Mark the number of people who belong to your household and used different types of gear in 2024 by age group."
  },
  "saaliit_maarat": {
    "instructions1": "Mark the ungutted weight of catch.",
    "title": "Mark in the table below an estimate of the catch that your household caught using different types of gear in Finland in 2024 (DO NOT INCLUDE FISH THAT WERE RELEASED ALIVE)."
  },
  "saaliit_pyydyksittain": {
    "control": {
      "label1": "Select species",
      "label2": "Other species, what?"
    },
    "title": "Select the species that your household caught using different types of gear in 2024. Include both catch species which have been taken and catch species which have been released alive."
  },
  "saaliit_ravinnoksi": {
    "control": {
      "label1": "Used for human consumption"
    },
    "title": "Estimate by species the amount (kg) of the catch that was used for human consumption.",
    "error": {
      "max_percentage": "The maximum allowed value is 100%"
    }
  },
  "saaliit_vapautetut": {
    "title": "Mark in the table below how much fish your household released alive.",
    "error": {
      "released_exceeds_caught": "The released amount cannot be greater than the caught amount."
    }
  },
  "welcome": {
    "content": "Welcome to the Recreational Fisheries survey of Natural Resources Institute Finland! Please insert your respondent id and choose ”start” in the lower panel of the screen. Respondent id is the twelve-digit code in the top right corner of the printed form, which has been sent by mail."
  },
  "yhteenveto": {
    "check_answers": "Please check your answers and edit them if necessary.",
    "instructions1": "You can download your answers by pressing the <strong>Download PDF</strong> button.",
    "instructions2": "After you have checked your answers, click <strong>Submit</strong>. You can come back later to check and change your answers. If you change the answers, please resubmit the answers.",
    "kalastusmatkat_warning": "Total number of fishing trips per area exceed 100 trips.",
    "kalastusmatkat_kulut_warning": "You entered costs of more than €6,000. The cost of purchasing boats and fishing equipment, insurance and similar costs should not be included. Please check the information you have provided and correct your answers if necessary.",
    "saaliit_maarat_warning": "Entered catch exceeds 100 kilograms per species.",
    "saveAsPDF": "Save as PDF",
    "title": "Summary"
  }
}
