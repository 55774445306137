import { Box, Grid, InputAdornment } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  EnterAwareTextField,
  FormLayout,
  Species,
  SpeciesUnit,
} from '../elements';
import { useFormPage } from '../hooks';
import useStyles from '../styles';
import { FormValues } from '../types/FormValues';
import { sumCatchAmountsPerSpecies } from '../util/formData';
import { optionalDecimalField } from '../util/numberField';

const schema = yup.object().shape({
  saaliit_vapautetut: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      vapautettu: optionalDecimalField,
    })
  ),
});

type SaaliitVapautetutValues = Pick<
  FormValues,
  'saaliit_vapautetut' | 'saalismaarat'
>;

export default function SaaliitVapautetut() {
  const { t } = useTranslation();
  const classes = useStyles({});
  const { control, handleSubmit, register, initialValues, syncing } =
    useFormPage<SaaliitVapautetutValues>({
      fields: ['saaliit_vapautetut', 'saalismaarat'],
      schema,
    });

  return (
    <FormLayout
      heading={t('saaliit_vapautetut.title')}
      syncing={syncing}
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography>{t('common.species')}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>{t('common.caught_up')}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>{t('common.released_catch')}</Typography>
          </Grid>

          {sumCatchAmountsPerSpecies(initialValues.saalismaarat).map(
            (laji: any, index: number) => (
              <React.Fragment key={index}>
                <Grid item xs={4} className={classes.gridLabel}>
                  <Typography>
                    <Species id={laji.id} />
                  </Typography>
                  <input
                    type="hidden"
                    defaultValue={laji.id}
                    {...register(`saaliit_vapautetut.${index}.id`)}
                  />
                </Grid>

                <Grid item xs={4} className={classes.gridLabel}>
                  <Typography data-testid="catch-amount">
                    {laji.maara || 0} <SpeciesUnit id={laji.id} />
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`saaliit_vapautetut.${index}.vapautettu`}
                    render={({ field, fieldState: { error } }) => (
                      <EnterAwareTextField
                        {...field}
                        type="number"
                        disabled={syncing}
                        error={!!error}
                        helperText={!!error && t(error.message!)}
                        inputProps={{
                          'aria-label': `Saaliista ${laji.id} vapautettu määrä`,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SpeciesUnit id={laji.id} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </React.Fragment>
            )
          )}
        </Grid>
      </Box>
    </FormLayout>
  );
}
